module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#6b63ff","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-160615042-1","head":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sparks DNA","short_name":"SparksDNA","start_url":"/","background_color":"#6b63ff","theme_color":"#6b63ff","display":"minimal-ui","icon":"./static/favicon/icon.png","icons":[{"src":"./static/favicon/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"./static/favicon/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"./static/favicon/icon-128x128.png","sizes":"128x128","type":"image/png"},{"src":"./static/favicon/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"./static/favicon/icon-152x152.png","sizes":"152x152","type":"image/png"},{"src":"./static/favicon/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"./static/favicon/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"./static/favicon/icon-512x512.png","sizes":"512x512","type":"image/png"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KLD3NQN","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
